<template>
  <div class="container">
    <h2>Relatorio de Preço Manual</h2>
    <h5>
      Total de Produtos: <strong>{{ report.length }}</strong>
    </h5>
    <b-table
      striped
      hover
      :items="report"
      :fields="fields"
      :filter="filter"
      thead-class="text-blue"
      class="table"
    >
      <template #cell(sku)="data">
        <b-link :href="`/product/${data.item.id_backoffice}`">{{
          data.item.sku[0]
        }}</b-link>
        <p v-if="data.item.sku.length > 1">
          (
          <a v-for="prod in data.item.sku" :key="prod">
            <a v-if="prod != data.item.sku[0]">
              {{ prod }}
            </a>
          </a>
          )
        </p>
      </template>
      <template #cell(ean)="data">
        <p v-for="prod in data.item.ean" :key="prod">
          {{ prod }}
        </p>
      </template>
      <template #cell(idivia_manual_price)="data">
        <input
          type="text"
          class="form-control"
          v-model="data.item.idivia_manual_price"
          @change="
            updateExtraInfo(
              data.item.id_backoffice,
              data.item.idivia_manual_price,
              data.item.kiooli_manual_price
            )
          "
        />
      </template>
      <template #cell(kiooli_manual_price)="data">
        <input
          type="text"
          class="form-control"
          v-model="data.item.kiooli_manual_price"
          @change="
            updateExtraInfo(
              data.item.id_backoffice,
              data.item.idivia_manual_price,
              data.item.kiooli_manual_price
            )
          "
        />
      </template>
    </b-table>
  </div>
</template>
  
  <script>
import { mapGetters } from "vuex";
export default {
  data: function () {
    return {
      report: [],
      filter: null,
      productName: "",
      productIdBackoffice: "",
      productSku: "",
      fields: [
        {
          key: "sku",
          label: "SKU/CNP",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "ean",
          label: "EAN",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "name",
          label: "Nome",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "stock",
          label: "Stock Real",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "idivia_manual_price",
          label: "Preço Manual IDIVIA",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "kiooli_manual_price",
          label: "Preço Manual KIOOLI",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
      ],
    };
  },
  methods: {
    async pCustoReport() {
      try {
        await this.$store.dispatch("getManualPrice");
        console.log(this.getManualPrices);
        this.report = this.getManualPrices;
      } catch (err) {
        console.log(err);
        alert(err);
      }
    },
    routeToProduct(productId) {
      this.$router.push(`product/${productId}`);
    },
    async updateExtraInfo(id, idivia_manual_price, kiooli_manual_price) {
      try {
        await this.$store.dispatch("updateProductExtraInfo", {
          id: id,
          idivia_manual_price: idivia_manual_price,
          kiooli_manual_price: kiooli_manual_price,
        });
        this.$swal("Completado", "Dados atualizados!", "success");
      } catch (error) {
        alert(error);
      }
      console.log("UPDATE", this.extraInfo);
    },
    // splitDate(date) {
    //   let split = date.split('T');
    //   return split[0].substring(0, split[0].length - 3);
    // },
  },
  async created() {
    this.pCustoReport();
  },
  computed: {
    ...mapGetters(["getManualPrices"]),
  },
};
</script>
  
  <style scoped>
.table {
  text-align: center;
}
.buttons {
  text-align: center;
  margin: 15px 0px 15px 0px;
}
h2 {
  text-align: center;
  margin-top: 5px;
}
h5 {
  text-align: center;
}
/* .btn {
    margin: 0px 5px 0px 5px;
  } */
</style>