var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('h2',[_vm._v("Relatorio de Preço Manual")]),_c('h5',[_vm._v(" Total de Produtos: "),_c('strong',[_vm._v(_vm._s(_vm.report.length))])]),_c('b-table',{staticClass:"table",attrs:{"striped":"","hover":"","items":_vm.report,"fields":_vm.fields,"filter":_vm.filter,"thead-class":"text-blue"},scopedSlots:_vm._u([{key:"cell(sku)",fn:function(data){return [_c('b-link',{attrs:{"href":`/product/${data.item.id_backoffice}`}},[_vm._v(_vm._s(data.item.sku[0]))]),(data.item.sku.length > 1)?_c('p',[_vm._v(" ( "),_vm._l((data.item.sku),function(prod){return _c('a',{key:prod},[(prod != data.item.sku[0])?_c('a',[_vm._v(" "+_vm._s(prod)+" ")]):_vm._e()])}),_vm._v(" ) ")],2):_vm._e()]}},{key:"cell(ean)",fn:function(data){return _vm._l((data.item.ean),function(prod){return _c('p',{key:prod},[_vm._v(" "+_vm._s(prod)+" ")])})}},{key:"cell(idivia_manual_price)",fn:function(data){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(data.item.idivia_manual_price),expression:"data.item.idivia_manual_price"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(data.item.idivia_manual_price)},on:{"change":function($event){return _vm.updateExtraInfo(
            data.item.id_backoffice,
            data.item.idivia_manual_price,
            data.item.kiooli_manual_price
          )},"input":function($event){if($event.target.composing)return;_vm.$set(data.item, "idivia_manual_price", $event.target.value)}}})]}},{key:"cell(kiooli_manual_price)",fn:function(data){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(data.item.kiooli_manual_price),expression:"data.item.kiooli_manual_price"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(data.item.kiooli_manual_price)},on:{"change":function($event){return _vm.updateExtraInfo(
            data.item.id_backoffice,
            data.item.idivia_manual_price,
            data.item.kiooli_manual_price
          )},"input":function($event){if($event.target.composing)return;_vm.$set(data.item, "kiooli_manual_price", $event.target.value)}}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }